<template>
  <div id="content-wrapper" style="background: #ffffff">
    <div class="container">
      <div class="row">
        <div class="project-page col-sm-12 mar-top-0" style="text-align: left">
          <h2>Explicit Rarity</h2>
          <p>
            To give general guidance on the hierarchy for the digital
            collectible art piece, the creators provide a set of explicit
            characteristics with differing degrees of rarity, e.g. men are much
            more common than robots. Unlike other collectibles, there is a
            second layer of scarcity imposed on top of the explicit traits via
            the implicit traits and the market for names.
          </p>
          <div style="text-align: center">
            <img
              src="../../public/images/explicit-traits.png"
              style="width: 350px"
            />
          </div>

          <h2>Implicit Rarity</h2>
          <p>
            Hashmasks are not a simple collectible, though, they are art. And
            art is not systematic. It is enigmatic. As the foremost blend of
            intelligently designed collectible items and artistic creation, many
            Hashmask traits or attributes are not explicitly accounted for. It
            encourages the consumer or rather the collective consumer to project
            his or her interpretation of value into the artwork. On top of that,
            the NCTs pass on the decision power over the rarest of all traits, a
            unique name, to the consumer, thus, eradicating the invisible line
            separating the creator and the consumer of the artwork.
          </p>
          &nbsp;
          <h2>Example of Implicit Rarity</h2>
          <p>
            On the highest level, there are 14 different types of masks. 12.5%
            of all characters wear an animal mask and only 5.9% wear a pixel
            mask. At first sight, pixel masks may seem more exclusive, but upon
            further inspection, you realize that there are only 13 Hashmasks
            that feature a unicorn mask, which is much more exclusive than the
            rarest of all pixel masks. Other examples of implicit traits with
            varying degrees of rarity are backgrounds, shirts, hairstyle and
            colors, and many more.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePictureSwipe from 'vue-picture-swipe';
import Vue from 'vue';

Vue.component('vue-picture-swipe', VuePictureSwipe);

export default {
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    let obj = [];
    for (let i = 0; i < 16384; i++) {
      let thumbnail;
      if (i < 15) {
        thumbnail =
          'https://hashmasksstore.blob.core.windows.net/hashmasksthumbs/' +
          i +
          '.png';
      } else {
        thumbnail =
          'https://hashmasksstore.blob.core.windows.net/hashmasksthumbs/0.png';
      }
      obj.push({
        src:
          'https://hashmasksstore.blob.core.windows.net/hashmaskspreview/' +
          i +
          '.png',
        thumbnail,
        w: 600,
        h: 824,
        alt: 'some numbers on a grey background', // optional alt attribute for thumbnail image
      });
    }
    this.items = obj;
  },
};
</script>
